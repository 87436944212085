import React from "react"

import logo from "../../../assets/solvLogo.svg"
import "./CashbackTnC.scss"

const CashbackTnC = () => {
  return (
    <>
     <div className='main-content'>
		    <div className='title'> CASHBACK TERMS & CONDITIONS </div>
            <p className='sub-heading'>DEFINITIONS</p>
            <p>For the purpose of these terms and conditions <b>(“Terms and Conditions”)</b>:</p>
            <ul style={{"listStyle":"disc"}}>
                <li>
                    <p><b>“Onboarded Customer”</b> shall mean a business entity that has on-boarded as a buyer in any of the Eligible Categories on the SOLV platform using its legal name, mobile number and relevant business identity proof, and is legally subsisting during the Offer Period;</p>
                </li>
                <li>
                    <p><b>“Bank Account”</b> means the details of the bank account provided by the Onboarded Customer which is linked to Onboarded Customer’s Solv account for undertaking transactions on Solv platform;</p>
                </li>
                <li>
                    <p><b>“Cash Back”</b> shall mean cash back equivalent to 1% of delivered order value which is issued to the Onboarded Customer upon such Onboarded Customer receiving the products on First Attempt Delivery, completing the purchase transaction and fulfilling the eligibility criteria stipulated for cash back as set out herein, during the Offer Period; </p>
                </li>
                <li>
                    <p><b>“Eligible Categories”</b> shall mean Apparel, Home Furnishing and Footwear categories;  </p>
                </li>
          
                <li>
                	<b>“First Attempt Delivery”</b> shall mean Solv’s delivery partner physically delivering the ordered goods at the Onboarded Customer’s location successfully in the first attempt in accordance with the mutually agreed delivery schedule;
                </li>
                <li>
                	<b>	“First Attempt Delivery Failure”</b> shall mean the failure of Solv’s delivery partner to deliver the products at the Onboarded Customer’s location in the first attempt arising out of unavailability of the Onboarded Customer, refusal by the Onboarded Customer to accept delivery or failure by Onboarded Customer to complete the payment in respect of the delivered goods; 
                </li>
                <li>
                	<b>“Offer”</b> shall mean the Cash Back awarded to the Onboarded Customers during the Offer Period; 
                </li>
                <li>
                	<b>“Offer Period”</b> shall mean the period commencing from 1 July 2022 until 31 July 2022 (both days inclusive), which may be amended by Solv at any time;  
                </li>
                <li>
                	<b>“Primary Terms and Conditions”</b> shall mean the terms and conditions governing the usage of the Solv platform, as applicable to the Onboarded Customer as separately provided on the Solv portal and/or application in addition to these Terms and Conditions.   
                </li>
                 
                
            </ul>
      
            <p className='sub-heading'>OFFERS</p>
            
            <ul style={{"listStyleType":"upper-roman"}}>
          
                        <li>
                            <p>
                            All Onboarded Customers in the Eligible Categories shall receive a Cash Back from Solv upon successful completion of purchase of products on First Attempt Delivery and payment of full amount in respect of the delivered orders during the Offer Period. Solv shall upon the expiry of return request period, credit the Cash Back amount within 10 (ten) working days from the date of First Attempt Delivery;  
                            </p>
                        </li>
                        <li>
                            <p>
                            Solv shall remit the Cash Back into the Onboarded Customer’s Bank Account;
                            </p>
                        </li>
                        <li>
                            <p>
                            In case of a First Attempt Delivery Failure of an order, the Onboarded Customers shall not receive any Cash Back in respect of such order;
                            </p>
                        </li>
                        <li>
                            <p>
                            An Onboarded Customer who has initiated a return request after receipt of products will not be eligible for Cash Back in respect of such order; 
                            </p>
                        </li>
                        <li>
                            <p>
                            The Cash Back will be provided only to those Onboarded Customers who provided their accurate Bank Account details to Solv. Solv shall not be required to verify the documents or Bank Account details provided by any Onboarded Customer and Solv shall not be liable for any wrong, inaccurate or incomplete Bank Account Details, GST, PAN or any other information provided by the Onboarded Customer; 
                            </p>
                        </li>
                        <li>
                            <p>
                            Any queries or disputes from Onboarded Customers regarding issuance/denial of Cash Back, can only be raised within 30 days from their date of credit or intimation of denial of the Cash Back. 
                            </p>
                        </li>
            </ul>

      
            <p className='sub-heading'>Other Terms and Conditions :</p>
            <ul style={{"listStyleType":"lower-alpha"}}>
                <li>
                    <p> 
                    	The participation in the Offer is entirely voluntary and it is understood that the participation by the Onboarded Customers shall be deemed to have been made on a voluntary basis.
                    </p>
                </li>
                <li>
                    <p> 
                    	All disputes are subject to the exclusive jurisdiction of the competent courts/tribunals of Bangalore.
                    </p>
                </li>
                <li>
                    <p> 
                    	All communication and notices about this Offer should be addressed to cs@solvezy.com. 
                    </p>
                </li>
                <li>
                    <p> 
                    In all matters relating to the Offer, including the payment or non-payment of Cash Back, the decisions of Solv shall be final and binding. 
                    </p>
                </li>
                <li>
                    <p> 
                    	The Onboarded Customers shall also be bound to comply with the Primary Terms and Conditions. These Terms shall be in addition to and not in substitution /derogation to the Primary Terms and Conditions governing the usage of Solv platform. 
                    </p>
                </li>
                <li>
                    <p> 
                    	Solv reserves the right to modify at any time, all or any of the terms applicable to the Offer, including the terms contained herein, without assigning any reasons or without any prior intimation whatsoever. 
                    </p>
                </li>
                <li>
                    <p> 
                    	Solv reserves the right to discontinue or terminate the Offer without assigning any reasons or without any prior intimation whatsoever at any time during the Offer Period. 
                    </p>
                </li>
                <li>
                    <p> 
                    Solv reserves the right to disqualify any merchant establishment or Onboarded Customer from the benefits of the Offer and initiate appropriate legal action, if any fraudulent activity is identified as being carried out for the purpose of availing the benefits under the Offer. 
                    </p>
                </li>
                <li>
                    <p> 
                    	In the event Solv suffers any loss or damage arising out of or resulting from any inaccurate information provided by the Onboarded Customer or breach of any applicable law or terms and conditions, the Onboarded Customer shall be liable to indemnify Solv for all the loss and damage incurred. 
                    </p>
                </li>
                <li>
                    <p> 
                    The Offer shall not be available in any jurisdiction in which providing such Offer is prohibited under applicable laws or otherwise for any reason whatsoever.
                    </p>
                </li>

            </ul>
    
       
            
		</div>
    </>
  )
}

export default CashbackTnC
